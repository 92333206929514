import React from 'react';
import styled from 'styled-components';
import { createTheme, TextField, Box, Button, Link, DialogTitle, TablePagination, TableSortLabel, TableCell, ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { MailOutline, Mail } from '@mui/icons-material';
import unlogged_first_pic from '@images/unlogged_first_pic.webp';
import wallpaperBackground_light from '@images/wallpaperBackground_light.svg';
import logo_small_light from '@images/logo_small_light.svg';
import logo_big_light from '@images/logo_big_light.svg';
import logo_big_dark from '@images/logo_big_dark.svg';  
import wallpaperBackground from '@images/wallpaperBackground.png';

export const HomeAccountBalance = {
  fontWeight: 500,
  color: 'white',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

export const PageTitle = styled(Box)({
  color: '#2c9464',
  fontWeight: 500,
  marginBottom: '20px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%' ,
  '& ::first-letter': {    // Added this style
    textTransform: 'uppercase'
  }
});

export const list_with_bottom_border = {
  color: '#023047',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  paddingBottom: '10px',
  borderBottom: '1px solid #8ECAE6',
  width: '100%'
};

export const updatePasswordStyle = {
  ...list_with_bottom_border,
  cursor: 'pointer',
  color: '#FFFFFF',
  backgroundColor: '#023047',
  padding: '8px 16px',
  borderRadius: '4px',
  display: 'inline-flex',
  alignItems: 'center',
  '&:hover': {
    backgroundColor: '#4285F4',
  },
  width: 'calc(100% - 24px)'
};

export const theme = createTheme({
  palette: {
    primary: {
      main: '#023047',
    },
    topmenu_icons: {
      main: '#023047',
    },
    leftmenu_icons: {
      main: '#2c9464',
    },
    leftmenu_sections: {
      main: '#ffffff',
    },
    leftmenu_selected_background: {
      main: '#FFFFFF',
    },
    leftmenu_selected_text: {
      main: '#023047',
    },
    leftmenu_not_selected_text: {
      main: '#FFFFFF', 
    },
    DrawerReduceButton: {
      main: '#FFFFFF',
    },
    DrawerReduceButton_hover: {
      main: '#8ECAE6',
    },
    IconsInDropdown: {
      main: '#023047',
    }
  },
  background: {
    default: '#FAFAFA',
    paper: '#FAFAFA',
  },
  components: {
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#023047',
          textDecorationColor: '#023047',
          '&:hover': {
            color: '#2c9464',
            textDecorationColor: '#2c9464',
          },
        }
      }
    },
    MuiBox: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          color: '#023047',
          fontFamily: 'Poppins, sans-serif',
          fontSize: '0.875rem',
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        input: {
          height: '48px',
          padding: '0 14px',
          lineHeight: '48px',
          '&::placeholder': {
            lineHeight: '48px',
            color: '#A3B5C0',
            opacity: 1,
            fontFamily: 'Poppins, sans-serif',
          },
        }
      }
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            height: '48px',  // Changed from 40px
            padding: '0 14px',
            display: 'flex',
            alignItems: 'center',
          },
          '& .MuiInputBase-root': {
            height: '48px',  // Changed from 50px
            borderColor: '#023047',
            '&:hover': {
              borderColor: '#2c9464',
            },
            '&.Mui-focused': {
              borderColor: '#2c9464',
            }
          },
          '& .MuiInputBase-input::placeholder': {
            color: '#A3B5C0',
            fontFamily: 'Poppins, sans-serif',
          },
          '& .MuiInputLabel-root': {
            top: '0px',
          },
          '& .MuiInputLabel-shrink': {
            top: 0,
          },
          '& .MuiFormLabel-root': {
            padding: '0 5px',
            backgroundColor: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#023047',
              borderWidth: '1px',
            },
            '&:hover fieldset': {
              borderColor: '#2c9464',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#2c9464',
            }
          },
          '& .MuiSelect-select': {
            padding: '12px 14px'
          },
          '& .MuiMenu-paper .MuiMenuItem-root': {
            padding: '16px 14px',  // Increased padding for menu items
            minHeight: '48px'      // Ensures minimum height for touch targets
          }
        }
      }
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          height: '48px',
          color: '#023047', 
        },
        select: {
          height: '48px',
          padding: '0 14px',
          fontSize: '1rem',
          display: 'flex',
          alignItems: 'center',
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          height: '48px',
        },
        input: {
          height: '48px',
          padding: '0 14px',
          fontSize: '1rem',
        }
      }
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontSize: '1rem',
          color: '#023047', // Match border color
        },
        outlined: {
          transform: 'translate(14px, 14px)',
          '&.MuiInputLabel-shrink': {
            transform: 'translate(14px, -6px) scale(0.75)',
          },
        }
      }
    },    
    MuiMenuItem: {
      styleOverrides: {
        root: {
          color: '#023047',
          fontSize: '0.875rem',
          minHeight: '48px',
          padding: '8px 14px',  // Adjusted padding
          display: 'flex',
          alignItems: 'center',
          gap: '12px',  // Added gap between icon and text
          '& .MuiSvgIcon-root': {  // Icon styling
            marginRight: '8px',
            fontSize: '20px',
          },
          '&:hover': {
            backgroundColor: '#f5f5f5',
          },
          '&.Mui-selected': {
            backgroundColor: '#f5f5f5',
            '&:hover': {
              backgroundColor: '#f5f5f5',
            }
          }
        }
      }
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          color: '#023047',
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          color: '#023047',
          fontWeight: 600,
          fontFamily: 'Poppins, sans-serif',
          '& .MuiTableSortLabel-root': {
            color: '#023047',
            '&:hover': {
              color: '#023047',
              '& .MuiTableSortLabel-icon': {
                color: '#023047'
              }
            },
            '&.Mui-active': {
              color: '#2c9464',
              '& .MuiTableSortLabel-icon': {
                color: '#2c9464'
              }
            }
          }
        },
        body: {
          fontFamily: 'Poppins, sans-serif',
          color: '#023047',
          alignItems: 'center',
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#023047',
          borderRight: '1px solid #2c9464',
          width: 220,
          '&.reduced-drawer': {
            width: 80,
          },
          padding: '10px',
          zIndex: 40,
        },
        paperAnchorRight: {
          width: '350px',
        }
      }
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          borderRadius: '8px',  // Added for rounded corners
          marginBottom: '4px',  // Added for spacing between items
          '&:hover': {
            backgroundColor: '#E1E5F2',
            color: '#022B3A',
          },
          '&.Mui-selected': {   // Added selected state
            backgroundColor: '#FFFFFF',
            color: '#023047',
            '&:hover': {
              backgroundColor: '#FFFFFF',
            }
          }
        }
      }
    },
    MuiListItemText: {         // Added to ensure text styling is consistent
      styleOverrides: {
        primary: {
          fontSize: '0.875rem',
          fontWeight: 500,
          color: 'inherit'
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          backgroundColor: '#023047',
          color: '#FFFFFF',
          borderRadius: '8px',
          textTransform: 'none',
          fontWeight: 600,
          fontSize: '15px',
          minHeight: '40px',
          lineHeight: 1,
          padding: '0 24px',
          display: 'inline-flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '48px',
          boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
          '&:hover': {
            backgroundColor: '#2c9464',
          },
          '&.Mui-disabled': {
            backgroundColor: '#A3B5C0',
            color: '#FFFFFF',
          },
          '@media (max-width: 600px)': {
            width: 'auto',
            marginBottom: '10px',
          },
          '@media (min-width: 601px)': {
            width: 'auto',
            '&:last-child': {
              marginRight: 0,
            }
          },
          '& .MuiButton-startIcon': {
            marginRight: '12px',
          }
        }
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          boxShadow: '0 4px 6px rgba(33, 158, 188, 0.1)'
        }
      }
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          boxShadow: 'none',
          height: '80px',
        }
      }
    }
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    body1: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.875rem',
      color: '#023047',
      textOverflow: 'ellipsis',
    },
    body2: {
      fontFamily: 'Poppins, sans-serif',
      fontSize: '0.875rem',
      color: '#023047',
      textOverflow: 'ellipsis',
    },
    h1: {
      fontFamily: 'Poppins, sans-serif',
      color: '#023047',
    },
    h2: {
      fontFamily: 'Poppins, sans-serif',
      color: '#023047',
    },
    h3: {
      fontFamily: 'Poppins, sans-serif',
      color: '#023047',
    },
    h4: {
      fontFamily: 'Poppins, sans-serif',
      color: '#023047',
    },
    h5: {
      fontFamily: 'Poppins, sans-serif',
      color: '#023047',
    }
  }
});

export const LoginCentralPic = styled(Box)`
  && {
    width: 720px;
    height: 457px;
    background: url(${unlogged_first_pic}) center no-repeat;
    background-size: cover;
    padding: 0;
    min-width: auto;    
  }
`;

export const UnauthenticatedMainContainer = styled(Box)({
  display: 'flex',
  height: '100vh',

  '@media (max-width: 1500px)': {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: `url(${wallpaperBackground})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    maxWidth: 'calc(100%)',
    minHeight: '100vh',
    flexDirection: 'column',
    padding: '10px',
  },

  '@media (min-width: 1501px)': {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100vh',
  },
});

export const UnauthenticatedLeftContainer = styled(Box)`
 && {
   flex: 3;
   background-image: url(${wallpaperBackground});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;

   @media (max-width: 1500px) {
     display: none;
   }
 }
`;

export const UnauthenticatedRightContainer = styled(Box)`
 && {
   & > * + * {
     padding-top: 2px;
   }

   color: #023047;

   @media (max-width: 1500px) {
     background-color: #ffffff;
     padding: 20px;
     border-radius: 8px;
     box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
     max-width: 400px;
     width: calc(100% - 40px);
     gap: 20px;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     text-align: center;
   }

   @media (min-width: 1501px) {
     flex: 1;
     display: flex;
     flex-direction: column;
     justify-content: center;
     align-items: center;
     text-align: center;
     padding: 70px;
     gap: 20px;
     max-width: 340px !important;
     width: 100%;
   }
 }
`;

export const UnauthenticatedLanguageFlagsContainer = styled.div`
  position: fixed;
  top: 10px;
  right: 10px;
  z-index: 1000;
  background-color: #ffffffb3;
  border-radius: 8px;
  padding: 5px 10px;
  box-shadow: 0 0 5px #0000004d;
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
`;

export const ForgotPassword = ({ children }) => {
  return (
    <Box style={{ width: '100%', textAlign: 'right' }}>
      <Link
        href="/auth/reset-my-password"
        style={{
          fontSize: '14px',
          color: '#023047',
          cursor: 'pointer'
        }}
      >
        {children}
      </Link>
    </Box>
  );
};

export const LoginLogoMobile = styled.div`
  width: 180px;
  height: 40px;

  background-image: url(${logo_big_dark});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  padding: 0;
  min-width: auto;
  border: none;
  outline: none;

  @media (max-width: 1500px) {
    display: block;
    margin: 20px auto;
  }
`;

export const TermsModal = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 300px;
    padding: 20px;
    background-color: rgba(255, 255, 255, 1);
    border-radius: 10px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    width: calc(100vw - 80px);
    height: calc(100vh - 80px);
    overflow-y: auto;
    max-width: 800px;
    max-height: 800px;
`;

export const KycMainDiv = styled.div`
   background-color: #6096ba99;
   background-image: url(${wallpaperBackground_light});
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   width: 100vw;
   height: 100vh;
   display: flex;
   justify-content: center;
   align-items: center;
   overflow-y: auto;
   margin: 0;
   padding: 0;
`;

export const KycContent = styled.div`
   height: 100%;
   width: 100%;
   position: relative;
`;

export const KycWebSdk = styled.div`
   width: 100%;
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, -50%);
`;

export const KycCloseButton = styled.button`
   position: absolute;
   top: 16px;
   left: 16px;
   z-index: 1000;
`;


export const OtpContainer = styled(Box)({
  display: 'flex',
  gap: '4px',
});

export const StyledOtpInput = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    border: '1px solid #ffffff !important',
    borderRadius: '5px',
    boxShadow: '3px 3px 6px #f0f0f0',

    [`@media (max-width: 1500px)`]: {
      width: '40px',
      margin: '2px',
    },

    [`@media (min-width: 1501px)`]: {
      width: '42px',
      margin: '7px',
    },
  },
});

export const ErrorContainer = styled(Box)(({ isModal }) => ({
  backgroundColor: '#ffe6e6',
  border: '1px solid #D52A20',
  borderRadius: '5px',
  paddingRight: '15px',
  paddingLeft: '15px',
  margin: isModal ? '20px' : '10px auto',
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  gap: '15px',
  minHeight: '50px',

  '&::before': {  // Using pseudo-element for the icon
    content: '"!"',
    backgroundColor: '#D52A20',
    borderRadius: '50%',
    width: '24px',
    height: '24px',
    flexShrink: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '16px',
    fontWeight: 700,
  },

  // Style for the text content
  '&': {
    color: '#D52A20',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.2,
  },
}));

export const SuccessContainer = styled(Box)(({ isModal }) => ({
  backgroundColor: '#d9f2d9',
  border: '1px solid #2e8b57',
  borderRadius: '5px',
  paddingRight: '15px',
  paddingLeft: '15px',
  margin: isModal ? '20px' : '10px 0',
  display: 'flex',
  alignItems: 'center',
  verticalAlign: 'middle',
  gap: '15px',
  minHeight: '50px',

  '&::before': {
    content: '"✓"',  // Checkmark symbol
    backgroundColor: '#2e8b57',
    borderRadius: '50%',
    width: '16px',
    height: '16px',
    flexShrink: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#fff',
    fontSize: '12px',
    fontWeight: 700,
    marginTop: '2px',
  },

  // Text styles
  '&': {
    color: '#2e8b57',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: 1.2,
  }
}));

export const NotificationItem = ({ message, handleReadMessage, index }) => {
  return (
    <Box
      onClick={() => handleReadMessage(message)}
      key={message.id || index}
      sx={{
        display: 'flex',
        alignItems: 'center',
        color: message.is_read ? '#8ECAE6' : '#ffffff',
        fontSize: '0.875rem',
        fontFamily: 'Poppins, sans-serif',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        py: '10px',
        borderBottom: '1px solid #2c9464',
        width: '100%',
        cursor: 'pointer',
      }}
    >
      {message.is_read ? (
        <Mail sx={{ color: message.is_read ? '#2c9464' : '#2c9464', mr: 1 }} />
      ) : (
        <MailOutline sx={{ color: message.is_read ? '#2c9464' : '#2c9464', mr: 1 }} />
      )}
      {message.subject}
    </Box>
  );
};

export const NotificationsSidebarTitle = styled(Box)({
  fontWeight: 600,
  color: '#8ECAE6',
  fontSize: '0.875rem',
  fontFamily: 'Poppins, sans-serif',
  marginBottom: '10px'
});

export const ModalTitle = styled(DialogTitle)({
  fontFamily: 'Poppins, sans-serif',
  fontWeight: 500,
  fontSize: '1.25rem',
  lineHeight: 1.6,
  padding: '16px 24px',
  textAlign: 'center',
});

export const BeneficiaryDetailsSummary = ({ children }) => (
  <Box
    sx={{
      color: '#8ECAE6',
      fontSize: '0.875rem',
      fontFamily: 'Poppins, sans-serif',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      py: '10px',
      borderBottom: '1px solid #f0f0f0',
      width: '100%'
    }}
  >
    {children}
  </Box>
);

export const FDTTableFooter = (props) => {
  return (
    <TablePagination
      {...props}
      style={{
        fontFamily: "'Poppins', sans-serif",
        color: "#023047"
      }}
    />
  );
};

export const FDTTableSortLabel = (props) => {
  return (
    <TableSortLabel
      {...props}
      style={{
        fontFamily: "'Poppins', sans-serif",
        color: "#023047"
      }}
      IconComponent={() => null} // This removes the up/down arrows
    />
  );
};

export const CurrencyTableCell = ({ amount, isCredit }) => {
  return (
    <TableCell align="right">
      <span style={{ color: isCredit ? '#3E885B' : '#D52A20' }}>
        {!isCredit && '-'}
        {amount}
      </span>
    </TableCell>
  );
};

export const UnauthenticatedLink = styled(Link)({
  color: "#2a9461 !important",
  cursor: "pointer",
  textDecoration: "underline",
  textDecorationColor: "#2a9461 !important",
  "&:hover": {
    color: "#2a9461 !important",
    textDecorationColor: "#2a9461 !important"
  }
});

export const LoggedInTopLeftLogo = styled.div(props => ({
  background: `url(${props.$drawerreduced ? logo_small_light : logo_big_light})`,
  backgroundSize: props.$drawerreduced ? '150px 150px' : '150px 60px',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center', // centers both horizontally and vertically
  height: '70px',
  width: '100%',
  display: 'flex',
  justifyContent: 'center', // centers horizontally in the flex container
  alignItems: 'center', // centers vertically in the flex container
  marginTop: '-10px',
  marginBottom: '-10px',
}));

export const TitleListItem = styled(ListItem)(({ theme }) => ({
  pointerEvents: 'none',
  userSelect: 'none',
  paddingBottom: '10px',
  textTransform: 'uppercase',
  fontFamily: 'Poppins, sans-serif',
  fontSize: '0.7rem',
  fontWeight: 600,
  color: '#cccccc',
  marginTop: '20px',
}));

export const StructureLeftMenuItem = ({ icon: Icon, description, path, drawerreduced, location, navigate, t }) => {
  const isSelected = location.pathname.startsWith(path);
  return (
    <ListItem
      $drawerreduced={drawerreduced}
      onClick={() => navigate(path)}
      sx={theme => ({
        backgroundColor: isSelected ? theme.palette.leftmenu_selected_background.main : 'transparent',
        color: isSelected ? theme.palette.leftmenu_selected_text.main : theme.palette.leftmenu_not_selected_text.main,
        fontWeight: 700,
        fontSize: '1.5rem',
        cursor: 'pointer',
        borderRadius: '5px',
        marginBottom: '5px',
        transition: 'background-color 0.3s ease',
        flexDirection: drawerreduced ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        marginRight: drawerreduced ? '0' : '0px',
        marginLeft: drawerreduced ? '0px' : '0px',
        padding: drawerreduced ? '12px 0' : undefined,
        '&:hover': {
          backgroundColor: '#2c9464',
          '& .MuiListItemIcon-root svg': {
            color: '#FFFFFF !important'
          },
          '& .MuiListItemText-root': {
            color: '#FFFFFF !important'
          }
        },
        '& .MuiListItemIcon-root': {
          minWidth: drawerreduced ? 'unset' : undefined,
          justifyContent: 'center',
          marginBottom: drawerreduced ? '8px' : undefined,
        },
        '& .MuiListItemText-root': {
          textAlign: drawerreduced ? 'center' : 'left',
        },
      })}
    >
      <ListItemIcon
        sx={{
          alignContent: 'center',
          minWidth: drawerreduced ? 'unset' : undefined,
          justifyContent: 'center',
          width: drawerreduced ? '100%' : undefined,
          color: isSelected ? theme.palette.leftmenu_selected_text.main : theme.palette.leftmenu_not_selected_text.main,
        }}>
        <Icon sx={{
          color: isSelected ? '#023047' : theme.palette.leftmenu_not_selected_text.main,
        }} />
      </ListItemIcon>
      <ListItemText
        sx={({
          paddingLeft: drawerreduced ? '10px' : undefined,
          paddingRight: drawerreduced ? '10px' : undefined,
        })}
        primary={t(description)} 
      />
    </ListItem>
  );
};
export const UnauthenticatedTitle = styled.div({
  fontWeight: 700,
  color: '#023047',
  fontSize: '1.5rem',
  fontFamily: 'Poppins, sans-serif',
  lineHeight: 1.334,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '16px'
});

export const WaitingForMobileApproval = styled.div({
  fontWeight: 700,
  color: '#023047',
  fontSize: '1.6rem',
  fontFamily: 'Poppins, sans-serif',
  lineHeight: 1.334,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
});


export const HomeWelcomeBoxLogo = styled.div({
  display: 'none', // added to ensure the div takes up space
});

export const SliderSettings = (currentSlide, setCurrentSlide) => ({
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 1,
  customPaging: (i) => (
    <div
      style={{
        width: '12px',
        height: '12px',
        borderRadius: '50%',
        backgroundColor: i === currentSlide ? '#E09F3E' : '#f0f0f0',
        padding: 0,
        margin: '0 4px',  // Maintain horizontal margin between dots
        cursor: 'pointer',
        transition: 'all 0.3s ease'
      }}
    ></div>
  ),
  dotsClass: 'slick-dots custom-dot-class',
  beforeChange: (current, next) => setCurrentSlide(next),
  appendDots: dots => (
    <div
      style={{
        width: '100%',
        padding: '40px 0 0 0',
        margin: 0,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <ul style={{ 
        display: 'flex',
        padding: 0,
        margin: 0,
        listStyle: 'none',
        gap: '12px',  // Increased gap between dots
        justifyContent: 'center'
      }}> 
        {dots}
      </ul>
    </div>
  )
});

export const LoggedInCentralContainer = styled(Box)(({ theme, $isMobile, $drawerreduced }) => ({
  position: 'relative',
  height: 'calc(100vh - 120px)',
  overflowY: 'auto',
  marginTop: '20px',
  paddingBottom: '20px',
  display: 'flex',
  flexDirection: 'column', // Added to maintain content layout

  ...($isMobile ? {
    marginLeft: 0,
    width: 'calc(100vw - 40px)',
    paddingLeft: '20px',
    paddingRight: '20px',
    textAlign: 'center',
  } : {
    marginLeft: $drawerreduced ? '140px' : '250px', // Added px for consistency
    width: $drawerreduced ? 'calc(100% - 150px)' : 'calc(100% - 280px)',
    color: '#FFFFFF',
    paddingLeft: $drawerreduced ? '0px' : '10px',
    paddingRight: $drawerreduced ? '0px' : '20px',
    marginRight: 'auto',
    transition: 'all 0.2s ease', // Smooth transition for drawer changes
  }),
}));

export const PIE_COLORS = [
  "#82E0AA", // Soft Green
  "#85C1E9", // Soft Blue
  "#A569BD", // Soft Purple
  "#F7DC6F", // Soft Gold
  "#76D7C4", // Soft Teal
  "#F0B27A", // Soft Apricot
  "#D7DBDD", // Soft Gray
  "#EBDEF0", // Soft Lavender
  "#CD6155", // Soft Brick Red
  "#F5B041", // Soft Amber
  "#AED6F1", // Soft Sky Blue
  "#A3E4D7", // Soft Mint
  "#FADBD8", // Soft Pink
  "#D5DBDB", // Soft Silver
  "#F5CBA7", // Soft Peach
  "#A9DFBF", // Soft Sage
  "#D2B4DE", // Soft Mauve
  "#FFF79A", // Soft Yellow
  "#F7C48B", // Soft Orange
  "#F78F8F"  // Soft Red
];   