import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Grid2, Card, CardContent, Typography, Select, Button, MenuItem, InputLabel, FormControl, ListItemIcon, ListItemText, Box, useMediaQuery, ThemeProvider, TextField } from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import ListIcon from '@mui/icons-material/List';
import SettingsIcon from '@mui/icons-material/Settings';
import CircularProgress from '@mui/material/CircularProgress';
import { fetchTrendChartData, get_page_data } from './Util_API_calls';
import { t } from './Util_format';
import config from './config/env.json';
import ListAltIcon from '@mui/icons-material/ListAlt';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import CollectionsIcon from '@mui/icons-material/Collections';
import BarChartIcon from '@mui/icons-material/BarChart';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import HomeIcon from '@mui/icons-material/Home';
import SavingsIcon from '@mui/icons-material/Savings';
import SecurityIcon from '@mui/icons-material/Security';
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import PieChartIcon from '@mui/icons-material/PieChart';
import { TableAssetsSharesEtfsCryptosCommodities } from './TableAssetsSharesEtfsCryptosCommodities';
import { TableAssetsBankaccountsCreditsDeposits } from './TableAssetsBankaccountsCreditsDeposits';
import { TableAssetsCollectibles } from './TableAssetsCollectibles';
import { TableAssetsBusinesses } from './TableAssetsBusinesses';
import { TableAssetsOthers } from './TableAssetsOthers';
import { TableAssetsSupers } from './TableAssetsSupers';
import { TableAssetsBonds } from './TableAssetsBonds';
import { TableAssetsVehiclesRealestates } from './TableAssetsVehiclesRealestates';
import { TableAssetsAll } from './TableAssetsAll';
import { ModalTaCreate } from './ModalTaCreate';
import { ModalTaList } from './ModalTaList';
import { ModalInstitutionsList } from './ModalInstitutionsList';
import { ModalInstitutionCreate } from './ModalInstitutionCreate';
import ModalAssetsActions from './ModalAssetsActions';
import ModalAssetsSettings from './ModalAssetsSettings';
import ModalAssetCreateAll from './ModalAssetCreateAll';
import ModalAssetCreateBankaccounts from './ModalAssetCreateBankaccounts';
import ModalAssetCreateCredits from './ModalAssetCreateCredits';
import ModalAssetBuySharesEtfs from './ModalAssetBuySharesEtfs';
import ModalAssetBuyCommodities from './ModalAssetBuyCommodities';
import ModalAssetSellSharesEtfs from './ModalAssetSellSharesEtfs';
import Modal_AssetSell_comodities from './ModalAssetSellCommodities';
import ModalAssetCreateVehicles from './ModalAssetCreateVehicles';
import ModalAssetCreateRealestates from './ModalAssetCreateRealestates';
import ModalAssetDeleteCloseBankAccount from './ModalAssetDeleteCloseBankAccount';
import ModalAssetDeleteCloseCredit from './ModalAssetDeleteCloseCredit';
import ModalAssetDeleteCloseVehicle from './ModalAssetDeleteCloseVehicle';
import ModalAssetDeleteCloseRealEstate from './ModalAssetDeleteCloseRealEstate';
import { PieChart, Pie, Cell, ResponsiveContainer } from 'recharts';
import { theme, PIE_COLORS, PageTitle } from '@style/styled.js';
import { Chart, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
Chart.register(...registerables);

const Icons = {
  ListAltIcon,
  AccountBalanceIcon,
  ShowChartIcon,
  BusinessCenterIcon,
  CollectionsIcon,
  BarChartIcon,
  CreditCardIcon,
  CurrencyBitcoinIcon,
  TrendingUpIcon,
  MoreHorizIcon,
  HomeIcon,
  SavingsIcon,
  SecurityIcon,
  StackedLineChartIcon,
  DirectionsCarIcon,
};

export const cellTextStyle = {
  color: 'theme.typography.body1.color', // Use the text color from the theme
};

const iconStyle = {
  color: '#2a9461',
  height: '24px',
  width: '24px'
};

const menuItemStyle = {
  display: 'flex',
  alignItems: 'center',
  height: '48px'
};

const textStyle_small_light = {
  fontWeight: 500,
  color: '#34403A',
  fontSize: '0.875rem',
  fontFamily: 'Poppins',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
};

function PageAssets({ onDataChange, ...props }) {
  const [category, setCategory] = React.useState('');
  const isMobile = useMediaQuery('(max-width:768px)');
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const [assets, setAssets] = useState([]);
  const [user_institutions, setUserInstitutions] = useState([]);
  const [trading_accounts, setTradingAccounts] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(() => {
    const savedPage = localStorage.getItem('currentPage');
    return savedPage !== null ? parseInt(savedPage, 10) : 0;
  });

  const { URLassetType } = useParams(); // Grab the URLassetType parameter from the URL
  const navigate = useNavigate();
  const [totalPL, setTotalPL] = useState(0);
  const [dailyTotals, setDailyTotals] = useState([]);
  const [PieDataTotal, setPieDataTotal] = useState(0);
  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [base_currency, setBaseCurrency] = useState('USD'); // Initialize with default currency
  const [show_PL_in, setshow_PL_in] = useState('CURRENCY'); // Initialize with default currency
  const [hoveredPieSliceIndices, setHoveredPieSliceIndices] = useState({});
  const [timeFrame, setTimeFrame] = useState('1M'); // Default to 1 month
  const [isLoadingAssets, setIsLoadingAssets] = useState(true);
  const [isLoadingTrendChart, setIsLoadingTrendChart] = useState(true);
  const [assetsClosed, setAssetsClosed] = useState([]);
  const [totalRealizedPL, setTotalRealizedPL] = useState(0);
  const [totalUnrealizedPL, setTotalUnrealizedPL] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);

  const { operation, assetID } = useParams();

  // Handle operations based on URL parameters (e.g., buy, sell, create)

  useEffect(() => {
    // BUY
    if (operation === 'buy' && URLassetType === 'shares') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuySharesModalOpen(true);
    }
    if (operation === 'buy' && URLassetType === 'etfs') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuyEtfsModalOpen(true);
    }
    if (operation === 'buy' && URLassetType === 'commodities') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetBuyCommoditiesModalOpen(true);
    }
    // SELL
    if (operation === 'sell' && URLassetType === 'shares' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellSharesModalOpen(true);
    }
    if (operation === 'sell' && URLassetType === 'etfs' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellEtfsModalOpen(true);
    }
    if (operation === 'sell' && URLassetType === 'commodities' && assetID && assets && assets.length > 0) {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setisAssetSellCommoditiesModalOpen(true);
    }
    // CREATE
    if (operation === 'create' && URLassetType === 'bankaccounts') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetCreateBankAccountModalOpen(true);
    }
    if (operation === 'list-banks' && URLassetType === 'bankaccounts') {
      setIsAssetCreateBankAccountModalOpen(false);
      handleOpenInstitutionsListModal(true);
    }
    if (operation === 'create-bank' && URLassetType === 'bankaccounts') {
      setIsAssetCreateBankAccountModalOpen(false);
      handleOpenInstitutionCreateModal(true);
      setIsTaCreateModalOpen(false);
    }
    if (operation === 'create-brokerage' && URLassetType === 'shares') {
      setIsTaCreateModalOpen(true);
      setisAssetBuySharesModalOpen(false);
    }
    if (operation === 'create-brokerage' && URLassetType === 'etfs') {
      setIsTaCreateModalOpen(true);
      setisAssetBuyEtfsModalOpen(false);
    }
    if (operation === 'create' && URLassetType === 'credits') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetCreateCreditModalOpen(true);
    }
    // DELETE
    if (operation === 'delete' && URLassetType === 'bankaccounts') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteBankAccountModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'credits') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteCreditModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'vehicles') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteVehicleModalOpen(true);
    }
    if (operation === 'delete' && URLassetType === 'realestates') {
      setSelectedAsset(assets.find(asset => asset.user_asset_id === assetID));
      setIsAssetDeleteRealEstateModalOpen(true);
    }

  }, [operation, URLassetType, assets]);


  // useEffect that calculates totals,Fetch assets list when category or page changes

  useEffect(() => {
    // Ensure each asset has a quantity
    if (assets) {
      assets.forEach((asset) => {
        if (!asset.dynamic_current_quantity) {
          asset.dynamic_current_quantity = 1;
        }
      });
    }

    // Calculate Total Unrealized Profit (totalUnrealizedPL)
    const totalUnrealized_PL = assets.reduce((acc, asset) => {
      if (asset.PL_unit && asset.PL_unit[base_currency]) {
        return acc + asset.PL_unit[base_currency] * asset.dynamic_current_quantity;
      }
      return acc;
    }, 0); // Initialize accumulator to 0
    setTotalUnrealizedPL(totalUnrealized_PL);

    // Calculate Total Realized Profit (totalRealizedPL)
    const totalRealized_PL = assetsClosed.reduce((acc, asset) => {
      if (asset.PL_base_currency) {
        return acc + parseFloat(asset.PL_base_currency);
      }
      return acc;
    }, 0); // Initialize accumulator to 0
    setTotalRealizedPL(totalRealized_PL);

    // Calculate Total Profit (totalProfit)
    const total_PL = totalRealized_PL + totalUnrealized_PL;
    setTotalProfit(total_PL);

    // Calculate Total Value (PieDataTotal) as before
    const total_value = assets.reduce((acc, asset) => {
      if (asset.product_last_value && asset.product_last_value[base_currency]) {
        return acc + asset.product_last_value[base_currency] * asset.dynamic_current_quantity;
      }
      if (asset.dynamic_balance && asset.dynamic_balance[base_currency]) {
        return acc + asset.dynamic_balance[base_currency];
      }
      if (asset.last_value && asset.last_value[base_currency]) {
        return acc + asset.last_value[base_currency];
      }
      return acc;
    }, 0); // Initialize accumulator to 0
    setPieDataTotal(total_value);

  }, [assets, assetsClosed, base_currency]);// Add assetsClosed to the dependency array, Make sure to include settings.base_currency in dependency array


  useEffect(() => {
    // Find the corresponding category title from the config
    const selectedCategory = config.ASSET_CATEGORIES.find(c => c.user_asset_type === URLassetType);
    if (selectedCategory) {
      setCategory(selectedCategory.user_asset_type);
    }
  }, [URLassetType]);

  // Handle page change/asset change/opening e closing modals

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    fetch_assets_list(newPage);
  };

  const handleChangeAssetType = (event) => {
    setIsLoading(true);
    const selectedCategoryTitle = event.target.value;
    const selectedCategory = config.ASSET_CATEGORIES.find(c => c.user_asset_type === selectedCategoryTitle);
    if (selectedCategory) {
      setCategory(selectedCategory.user_asset_type); // Set the user_asset_type
      navigate(`/assets/${selectedCategory.user_asset_type}`);
    }
  };

  const findCategory = (selected) => {
    return config.ASSET_CATEGORIES.find(item => item.user_asset_type === selected);
  };

  const accountIcon = (type) => {
    const category = config.ASSET_CATEGORIES.find(c => c.user_asset_type === type);
    if (category && category.icon) {
      const IconComponent = Icons[category.icon]; // Assuming you have an object mapping string to icon components
      return <IconComponent style={iconStyle} />;
    }
    return <ListIcon style={iconStyle} />;
  };

  // Fetch the list of assets based on the selected category and page

  const fetch_assets_list = async (page = null) => {
    const currentPage = page !== null ? page : parseInt(localStorage.getItem('currentPage'), 10) || 0;
    setIsLoadingAssets(true);
    const selectedCategory = config.ASSET_CATEGORIES.find(acc => acc.user_asset_type === URLassetType);
    if (!selectedCategory) {
      console.error("No selected category found for category data fetch (" + selectedCategory + ")");
      return;
    }
    setIsLoading(true);
    try {
      setAssets([]);
      const data = await get_page_data('assets', selectedCategory.user_asset_type, currentPage);
      if (data && data.assets_open) {
        setBaseCurrency(data.settings.base_currency);
        setAssets(data.assets_open);
        setAssetsClosed(data.assets_closed || []); // Add this line to store closed assets
        setUserInstitutions(data.user_institutions);
        setTradingAccounts(data.trading_accounts);
      } else {
        setAssets([]);
        setAssetsClosed([]); // Reset assetsClosed if no data
      }
    } catch (error) {
      console.error('Error fetching assets list:', error);
      setAssets([]);
      setAssetsClosed([]); // Reset assetsClosed on error
    } finally {
      setIsLoading(false);
    }
    setIsLoadingAssets(false);
  };


  // Fetch trend chart data for the selected category and base currency

  const fetch_trend_chart_data = async () => {
    setIsLoadingTrendChart(true);

    const selectedCategory = config.ASSET_CATEGORIES.find(acc => acc.user_asset_type === URLassetType);

    // Check if the selectedCategory is not found or user_asset_type is empty
    if (!selectedCategory || !selectedCategory.user_asset_type) {
      console.error("No valid category found for trend chart data fetch.");
      setIsLoadingTrendChart(false);
      return;
    }

    if (!selectedCategory) {
      console.error("No selected category found for trend chart data fetch (" + selectedCategory + ")");
      return;
    }
    try {
      const data = await fetchTrendChartData(selectedCategory.user_asset_type, 'this_year', base_currency);
      if (data && data.daily_totals) {
        setDailyTotals(data.daily_totals);
        // console.log(data.daily_totals);
      } else {
        // console.log("No trend data found for category: ", category);
      }
    } catch (error) {
      console.error('Error fetching trend data:', error);
    }
    setIsLoadingTrendChart(false);
  };

  // useEffect for fetching assets list
  useEffect(() => {
    if (category) {
      fetch_assets_list(currentPage);
    }
  }, [category, URLassetType, currentPage]);

  // useEffect for fetching trend chart data
  useEffect(() => {
    if (base_currency && category) {
      fetch_trend_chart_data();
    }
  }, [base_currency, category]);

  const loss_color = {
    color: '#c0392b',
  };

  const gain_color = {
    color: '#2a9461',
  };

  useEffect(() => {
    localStorage.setItem('currentPage', currentPage);
  }, [currentPage]);

  const [isActionsModalOpen, setIsActionsModalOpen] = useState(false);
  const [isAssetCreateBankAccountModalOpen, setIsAssetCreateBankAccountModalOpen] = useState(false);
  const [isAssetCreateCreditModalOpen, setIsAssetCreateCreditModalOpen] = useState(false);
  const [isAssetBuySharesModalOpen, setisAssetBuySharesModalOpen] = useState(false);
  const [isAssetBuyEtfsModalOpen, setisAssetBuyEtfsModalOpen] = useState(false);
  const [isAssetSellShareModalOpen, setisAssetSellSharesModalOpen] = useState(false);
  const [isAssetSellEtfModalOpen, setisAssetSellEtfsModalOpen] = useState(false);
  const [isAssetSellCommoditiesModalOpen, setisAssetSellCommoditiesModalOpen] = useState(false);
  const [isAssetBuyCommoditiesModalOpen, setisAssetBuyCommoditiesModalOpen] = useState(false);
  const [isAssetDeleteBankAccountModalOpen, setIsAssetDeleteBankAccountModalOpen] = useState(false);
  const [isAssetDeleteCreditModalOpen, setIsAssetDeleteCreditModalOpen] = useState(false);
  const [isAssetCreateVehicleModalOpen, setIsAssetCreateVehicleModalOpen] = useState(false);
  const [isAssetDeleteVehicleModalOpen, setIsAssetDeleteVehicleModalOpen] = useState(false);
  const [isAssetCreateRealEstateModalOpen, setIsAssetCreateRealEstateModalOpen] = useState(false);
  const [isAssetDeleteRealEstateModalOpen, setIsAssetDeleteRealEstateModalOpen] = useState(false);
  const [isTaCreateModalOpen, setIsTaCreateModalOpen] = useState(false);
  const [isTaListModalOpen, setIsTaListModalOpen] = useState(false);
  const [isInstitutionsListModalOpen, setIsInstitutionsListModalOpen] = useState(false);
  const [isInstitutionsCreateModalOpen, setIsInstitutionsCreateModalOpen] = useState(false);
  const [isAssetCreateAllModalOpen, setIsAssetCreateAllModalOpen] = useState(false);
  const [pieData, setPieData] = useState([]);

  const handleOpenSettingsModal = () => {
    setIsSettingsModalOpen(true);
  };

  const handleOpenActionsModal = (asset) => {
    setSelectedAsset(asset);
    navigate(`/assets/${URLassetType}/actions/${asset.user_asset_id}`);
    setIsActionsModalOpen(true);
  };

  const handleOpenAssetCreateBankAccountModal = () => {
    setIsAssetCreateBankAccountModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  };

  const handleOpenAssetCreateCreditModal = () => {
    setIsAssetCreateCreditModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }
  const handleOpenAssetBuyShareModal = () => {
    setisAssetBuySharesModalOpen(true);
    navigate(`/assets/${URLassetType}/buy`);
  };

  const handleOpenAssetBuyEtfModal = () => {
    setisAssetBuyEtfsModalOpen(true);
    navigate(`/assets/${URLassetType}/buy`);
  };

  const handleOpenAssetCreateVehicleModal = () => {
    setIsAssetCreateVehicleModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }

  const handleOpenAssetCreateRealEstateModal = () => {
    setIsAssetCreateRealEstateModalOpen(true);
    navigate(`/assets/${URLassetType}/create`);
  }

  const handleOpenTaCreateModal = () => {
    setIsTaListModalOpen(false);
    setIsTaCreateModalOpen(true);
  };

  const handleOpenInstitutionCreateModal = () => {
    setIsInstitutionsCreateModalOpen(true);
  }

  const handleOpenAssetCreateAllModal = () => {
    navigate(`/assets/${URLassetType}/create`);
    setIsAssetCreateAllModalOpen(true);
  }

  const handleOpenInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(true);
  }

  const handleOpenTaListModal = (asset) => {
    setIsTaListModalOpen(true);
  }

  const handleCloseAssetCreateBankAccountModal = () => {
    setIsAssetCreateBankAccountModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  };

  const handleCloseAssetCreateCreditModal = () => {
    setIsAssetCreateCreditModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetCreateVehicleModal = () => {
    setIsAssetCreateVehicleModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetCreateRealEstateModal = () => {
    setIsAssetCreateRealEstateModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyShareModal = () => {
    setisAssetBuySharesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyEtfModal = () => {
    setisAssetBuyEtfsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetBuyCommoditiesModal = () => {
    setisAssetBuyCommoditiesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetBankAccountModal = () => {
    setIsAssetDeleteBankAccountModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetCreditModal = () => {
    setIsAssetDeleteCreditModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetVehicleModal = () => {
    setIsAssetDeleteVehicleModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseDeleteAssetRealEstateModal = () => {
    setIsAssetDeleteRealEstateModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellShareModal = () => {
    setisAssetSellSharesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellEtfModal = () => {
    setisAssetSellEtfsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseAssetSellCommodityModal = () => {
    setisAssetSellCommoditiesModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  }

  const handleCloseSettingsModal = () => {
    setIsSettingsModalOpen(false);
    navigate(`/assets/${URLassetType}`);
  };

  const [selectedAsset, setSelectedAsset] = useState(null);

  const handleCloseActionsModal = () => {
    setIsActionsModalOpen(false);
    // navigate(`/assets/${URLassetType}`);
  };

  const handleCloseTaCreateModal = () => {
    setIsTaCreateModalOpen(false);
  }

  const handleCloseTaListModal = () => {
    setIsTaListModalOpen(false);
  };

  const handleCloseInstitutionsListModal = () => {
    setIsInstitutionsListModalOpen(false);
  };

  const handleCloseInstitutionsCreateModal = () => {
    setIsInstitutionsCreateModalOpen(false);
  };

  const handleCloseAssetCreatAllModal = () => {
    setIsAssetCreateAllModalOpen(false);
  }

  const renderSharesPieChart = () => {
    if (pieData.length === 0) {
      return <Typography>No data available</Typography>;
    }

    const colors = PIE_COLORS;

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius={120}
            fill="#8884d8"
            labelLine={false}
            label={(entry) => {
              const formattedValue =
                entry.percent != null && !isNaN(entry.percent)
                  ? `${(entry.percent * 100).toFixed(2)}%`
                  : '0%';
              return `${formattedValue} | ${entry.name}`;
            }}
          >
            {pieData.map((entry, index) => (
              <Cell key={`cell-value-${entry.name}-${index}`} fill={PIE_COLORS[index % PIE_COLORS.length]} />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };


  // Render the appropriate table based on the asset type

  const renderTableForCategory = (URLassetType) => {
    switch (URLassetType) {
      case "all":
        let assets_aggregated = [];
        let assetTypeIndexMap = {};
        assets.forEach(asset => {
          // Check if this asset type has been encountered before
          if (!(asset.user_asset_type in assetTypeIndexMap)) {
            // Add a new object for this asset type
            assetTypeIndexMap[asset.user_asset_type] = assets_aggregated.length;
            assets_aggregated.push({
              user_asset_type: asset.user_asset_type,
              last_value: {},
              PL_unit: {}
            });
          }

          let index = assetTypeIndexMap[asset.user_asset_type];

          // Aggregate last_value for each currency
          for (const currency in asset.last_value) {
            if (!(currency in assets_aggregated[index].last_value)) {
              assets_aggregated[index].last_value[currency] = 0;
            }
            assets_aggregated[index].last_value[currency] += asset.last_value[currency];
          }

          // Aggregate PL_unit for each currency
          for (const currency in asset.PL_unit) {
            if (!(currency in assets_aggregated[index].PL_unit)) {
              assets_aggregated[index].PL_unit[currency] = 0;
            }
            assets_aggregated[index].PL_unit[currency] += asset.PL_unit[currency];
          }
        });
        return (
          <TableAssetsAll
            key={searchTerm}
            assets_open={assets_aggregated}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
          />
        );
      case 'bankaccounts':
      case 'credits':
      case 'deposits':
        return (
          <TableAssetsBankaccountsCreditsDeposits
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'shares':
      case 'etfs':
      case 'commodities':
      case 'cryptos':
        return (
          <TableAssetsSharesEtfsCryptosCommodities
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'collectibles':
        return (
          <TableAssetsCollectibles
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'businesses':
        return (
          <TableAssetsBusinesses
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'bonds':
        return (
          <TableAssetsBonds
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'others':
        return (
          <TableAssetsOthers
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'supers':
        return (
          <TableAssetsSupers
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      case 'vehicles':
      case 'realestates':
        return (
          <TableAssetsVehiclesRealestates
            key={searchTerm}
            assets_open={assets}
            UserSettings={userData?.UserSettings}
            onPageChange={handlePageChange}
            searchTerm={searchTerm}
            category={category}
            base_currency={base_currency}
            show_PL_in={show_PL_in}
            onChange={(event) => setBaseCurrency(event.target.value)}
            URLassetType={URLassetType}
            handleOpenActionsModal={handleOpenActionsModal}
          />
        );
      default:
        return <div>Unsupported asset type: {category}</div>;
    }
  };

  const onPieEnter = (pieInfoToShow, index) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: index }));
  };

  const onPieLeave = (pieInfoToShow) => {
    setHoveredPieSliceIndices(prev => ({ ...prev, [pieInfoToShow]: -1 }));
  };


  const renderPiesForCategory = () => {
    if (pieData.length === 0) {
      return <Typography>No data available</Typography>;
    }

    return (
      <ResponsiveContainer width="100%" height={600}> {/* Increased height */}
        <PieChart>
          <Pie
            data={pieData}
            dataKey="value"
            nameKey="name"
            cx="50%"
            cy="50%"
            outerRadius="75%" // Adjusted for better fit
            fill="#8884d8"
            labelLine={false}
            label={(entry) => {
              const formattedValue =
                entry.percent != null && !isNaN(entry.percent)
                  ? `${(entry.percent * 100).toFixed(2)}%`
                  : '0%';
              return `${formattedValue} | ${entry.name}`;
            }}
          >
            {pieData.map((entry, index) => (
              <Cell
                key={`cell-value-${entry.name}-${index}`}
                fill={PIE_COLORS[index % PIE_COLORS.length]}
              />
            ))}
          </Pie>
        </PieChart>
      </ResponsiveContainer>
    );
  };



  useEffect(() => {
    if (assets.length > 0) {
      if (URLassetType === 'shares') {
        const buildPieData = () => {
          // Calculate total value of shares
          const totalSharesValue = assets.reduce((acc, asset) => {
            const lastValue = parseFloat(asset.product_last_value?.[base_currency]);
            const quantity = parseFloat(asset.dynamic_current_quantity);

            if (!isNaN(lastValue) && !isNaN(quantity)) {
              return acc + lastValue * quantity;
            }
            return acc;
          }, 0);

          if (totalSharesValue === 0) {
            return [];
          }

          // Prepare data for the pie chart
          const pieData = assets
            .map((asset) => {
              const lastValue = parseFloat(asset.product_last_value?.[base_currency]);
              const quantity = parseFloat(asset.dynamic_current_quantity);

              if (!isNaN(lastValue) && !isNaN(quantity)) {
                const totalValue = lastValue * quantity;

                return {
                  name: asset.user_asset_label || asset.product_code || 'Unknown',
                  value: totalValue,
                };
              }
              // Exclude assets with invalid data
              return null;
            })
            .filter((item) => item !== null);

          // Sort data by value descending
          pieData.sort((a, b) => b.value - a.value);

          // Log the pieData for debugging
          console.log('Pie Data:', pieData);

          return pieData;
        };

        setPieData(buildPieData());
      } else {
        // When URLassetType is not 'shares', set pieData to an empty array
        setPieData([]);
      }
    } else {
      setPieData([]);
    }
  }, [assets, base_currency, URLassetType]);



  const chartData = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
    datasets: [
      {
        label: 'Value of my shares',
        data: dailyTotals,
        fill: false,
        borderColor: 'rgb(75, 192, 192)',
        tension: 0.1
      },
      // You can add more datasets here
    ],
  };

  const ChartOptions = {
    scales: {
      y: {
        beginAtZero: true
      }
    },
    animation: {
      duration: 0 // No animation
    },
    hover: {
      animationDuration: 0 // No animation on hover
    },
    responsiveAnimationDuration: 0 // No animation on window resizing
  };


  const updateChartData = (selectedTimeFrame) => {
    // Logic to update data based on selectedTimeFrame
    // This might involve fetching new data or filtering your dataset
    // For example:
    // if (selectedTimeFrame === '1M') { ... }
    // ...
  };

  let carouselItems = [];

  if (URLassetType === 'shares') {
    carouselItems.push({
      title: 'value',
      renderFunc: () => renderPiesForCategory(URLassetType, 'value'),
    });
    // Add other carousel items specific to 'shares' if needed
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'currency',
      renderFunc: () => renderPiesForCategory(URLassetType, 'currency'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'country',
      renderFunc: () => renderPiesForCategory(URLassetType, 'country'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'PL_up',
      renderFunc: () => renderPiesForCategory(URLassetType, 'PL_up'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'PL_down',
      renderFunc: () => renderPiesForCategory(URLassetType, 'PL_down'),
    });
  }

  if (URLassetType === 'shares' || URLassetType === 'etfs') {
    carouselItems.push({
      title: 'brokerage_account',
      renderFunc: () => renderPiesForCategory(URLassetType, 'brokerage_account'),
    });
  }

  const [sortedCategories, setSortedCategories] = useState([]);
  useEffect(() => {
    const firstItem = config.ASSET_CATEGORIES[0];
    const lastItem = config.ASSET_CATEGORIES[config.ASSET_CATEGORIES.length - 1];
    const middleItems = config.ASSET_CATEGORIES.slice(1, -1).sort((a, b) => {
      const labelA = t('categories_assets_' + a.user_asset_type).toLowerCase();
      const labelB = t('categories_assets_' + b.user_asset_type).toLowerCase();
      return labelA.localeCompare(labelB);
    });
    setSortedCategories([firstItem, ...middleItems, lastItem]);
  }, [t, config.ASSET_CATEGORIES]); // Add other dependencies if needed

  const handleRefreshAssets = () => {
    fetch_assets_list(currentPage);
  };

  return (
    <ThemeProvider theme={theme}>
      {/* Modals */}

      {isSettingsModalOpen && (
        <ModalAssetsSettings
          isOpen={isSettingsModalOpen}
          onClose={handleCloseSettingsModal}
          setBaseCurrency={setBaseCurrency}
          setshow_PL_in={setshow_PL_in}
          currentCurrency={base_currency}
          currentshow_PL_in={show_PL_in}
        />
      )}
      {isActionsModalOpen && (
        <ModalAssetsActions
          isOpen={isActionsModalOpen}
          onClose={handleCloseActionsModal}
          asset={selectedAsset}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
        />
      )}
      {isTaCreateModalOpen && (
        <ModalTaCreate
          isOpen={isTaCreateModalOpen}
          onClose={handleCloseTaCreateModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
          trading_accounts={trading_accounts}
        />
      )}
      {isTaListModalOpen && (
        <ModalTaList
          isOpen={isTaListModalOpen}
          onClose={handleCloseTaListModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
          trading_accounts={trading_accounts}
          handleOpenTaCreateModal={handleOpenTaCreateModal}
        />
      )}
      {isInstitutionsListModalOpen && (
        <ModalInstitutionsList
          isOpen={isInstitutionsListModalOpen}
          onClose={handleCloseInstitutionsListModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isInstitutionsCreateModalOpen && (
        <ModalInstitutionCreate
          isOpen={isInstitutionsCreateModalOpen}
          onClose={handleCloseInstitutionsCreateModal}
          assetType={URLassetType}
          user_institutions={user_institutions}
          handleRefreshAssets={handleRefreshAssets}
        />
      )}
      {isAssetCreateAllModalOpen && (
        <ModalAssetCreateAll
          isOpen={isAssetCreateAllModalOpen}
          onClose={handleCloseAssetCreatAllModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isAssetCreateBankAccountModalOpen && (
        <ModalAssetCreateBankaccounts
          isOpen={isAssetCreateBankAccountModalOpen}
          onClose={handleCloseAssetCreateBankAccountModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isAssetCreateCreditModalOpen && (
        <ModalAssetCreateCredits
          isOpen={isAssetCreateCreditModalOpen}
          onClose={handleCloseAssetCreateCreditModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isAssetCreateVehicleModalOpen && (
        <ModalAssetCreateVehicles
          isOpen={isAssetCreateVehicleModalOpen}
          onClose={handleCloseAssetCreateVehicleModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isAssetCreateRealEstateModalOpen && (
        <ModalAssetCreateRealestates
          isOpen={isAssetCreateRealEstateModalOpen}
          onClose={handleCloseAssetCreateRealEstateModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          user_institutions={user_institutions}
        />
      )}
      {isAssetBuySharesModalOpen && (
        <ModalAssetBuySharesEtfs
          isOpen={isAssetBuySharesModalOpen}
          onClose={handleCloseAssetBuyShareModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
          asset={selectedAsset}
        />
      )}
      {isAssetBuyEtfsModalOpen && (
        <ModalAssetBuySharesEtfs
          isOpen={isAssetBuyEtfsModalOpen}
          onClose={handleCloseAssetBuyEtfModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
          asset={selectedAsset}
        />
      )}
      {isAssetSellShareModalOpen && (
        <ModalAssetSellSharesEtfs
          isOpen={isAssetSellShareModalOpen}
          onClose={handleCloseAssetSellShareModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
          asset={selectedAsset}
        />
      )}
      {isAssetSellEtfModalOpen && (
        <ModalAssetSellSharesEtfs
          isOpen={isAssetSellEtfModalOpen}
          onClose={handleCloseAssetSellEtfModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
          asset={selectedAsset}
        />
      )}
      {isAssetSellCommoditiesModalOpen && (
        <Modal_AssetSell_comodities
          isOpen={isAssetSellCommoditiesModalOpen}
          onClose={handleCloseAssetSellCommodityModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
          asset={selectedAsset}
        />
      )}

      {isAssetBuyCommoditiesModalOpen && (
        <ModalAssetBuyCommodities
          isOpen={isAssetBuyCommoditiesModalOpen}
          onClose={handleCloseAssetBuyCommoditiesModal}
          assetType={URLassetType}
          handleRefreshAssets={handleRefreshAssets}
          trading_accounts={trading_accounts}
        />
      )}
      {isAssetDeleteBankAccountModalOpen && (
        <ModalAssetDeleteCloseBankAccount
          isOpen={isAssetDeleteBankAccountModalOpen}
          onClose={handleCloseDeleteAssetBankAccountModal}
          asset={selectedAsset}
          handleRefreshAssets={handleRefreshAssets}
        />
      )}
      {isAssetDeleteCreditModalOpen && (
        <ModalAssetDeleteCloseCredit
          isOpen={isAssetDeleteCreditModalOpen}
          onClose={handleCloseDeleteAssetCreditModal}
          asset={selectedAsset}
          handleRefreshAssets={handleRefreshAssets}
        />
      )}
      {isAssetDeleteVehicleModalOpen && (
        <ModalAssetDeleteCloseVehicle
          isOpen={isAssetDeleteVehicleModalOpen}
          onClose={handleCloseDeleteAssetVehicleModal}
          asset={selectedAsset}
          handleRefreshAssets={handleRefreshAssets}
        />
      )}
      {isAssetDeleteRealEstateModalOpen && (
        <ModalAssetDeleteCloseRealEstate
          isOpen={isAssetDeleteRealEstateModalOpen}
          onClose={handleCloseDeleteAssetRealEstateModal}
          asset={selectedAsset}
          handleRefreshAssets={handleRefreshAssets}
        />
      )}

      {/* Main Content Grid2 */}

      <Grid2 container rowSpacing={5} columnSpacing={2} sx={{ width: '100%', m: 0, marginTop: '10px' }}>
        {/* Asset Selection and Operations Section */}
        <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', paddingTop: '10px' }}>
            <CardContent>
              <FormControl fullWidth>
                <InputLabel id="account-select-label" className="assets_input-label">
                  {t('select_asset_type')}
                </InputLabel>
                <Select
                  labelId="account-select-label"
                  id="account-select"
                  value={category}
                  label={t('select_asset_type')}
                  onChange={handleChangeAssetType}
                  color="primary"
                  IconComponent={ListIcon}
                  renderValue={(selected) => (
                    <Box display="flex" alignItems="center">
                      {findCategory(selected) ? accountIcon(findCategory(selected).user_asset_type) : null}
                      <Typography variant="body2" noWrap style={{ ...textStyle_small_light, marginLeft: '8px' }}>
                        {t('categories_assets_' + selected)}
                      </Typography>
                    </Box>
                  )}
                >
                  {sortedCategories.map((item) => (
                    <MenuItem key={item.user_asset_type} value={item.user_asset_type} style={menuItemStyle}>
                      <ListItemIcon>{accountIcon(item.user_asset_type)}</ListItemIcon>
                      <ListItemText primary={t('categories_assets_' + item.user_asset_type)} />
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </CardContent>
          </Card>
        </Grid2>


        <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', paddingTop: '10px' }}>
            <CardContent>
              <TextField
                fullWidth
                name="search_filter_unique"
                label={t('search')}
                variant="outlined"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                autoComplete="no-autofill"
              />
            </CardContent>
          </Card>
        </Grid2>


        <Grid2 size={{ xs: 4, sm: 4, md: 4, lg: 4, xl: 4 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', paddingTop: '10px' }}>
            <CardContent>

              <Grid2 container spacing={1}>
                {/* Buttons for 'all' asset type */}
                {URLassetType === 'all' && (
                  <Grid2 xs={12}>
                    <Button
                      onClick={handleOpenAssetCreateAllModal}
                      disabled={isLoading}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('record_new_asset_button_' + URLassetType)}
                    </Button>
                  </Grid2>
                )}

                {/* Buttons for 'bankaccounts' asset type */}
                {URLassetType === 'bankaccounts' && (
                  <>
                    <Grid2 xs={12}>
                      <Button
                        onClick={handleOpenInstitutionCreateModal}
                        disabled={isLoading}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('create_institution')}
                      </Button>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Button
                        onClick={handleOpenInstitutionsListModal}
                        disabled={isLoading}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('list_institutions')}
                      </Button>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Button
                        onClick={handleOpenAssetCreateBankAccountModal}
                        disabled={isLoading}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('record_new_asset_button_' + URLassetType)}
                      </Button>
                    </Grid2>
                  </>
                )}

                {/* Buttons for 'shares' and 'etfs' asset types */}
                {(URLassetType === 'shares' || URLassetType === 'etfs') && (
                  <>
                    <Grid2 xs={12}>
                      <Button
                        fullWidth
                        onClick={handleOpenTaListModal}
                        disabled={isLoading}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('show_ta_' + URLassetType)}
                      </Button>
                    </Grid2>
                    <Grid2 xs={12}>
                      <Button
                        onClick={handleOpenAssetBuyShareModal}
                        disabled={isLoading}
                        style={{ textTransform: 'capitalize' }}
                      >
                        {t('record_new_asset_button_' + URLassetType)}
                      </Button>
                    </Grid2>
                  </>
                )}

                {/* Button for 'credits' asset type */}
                {URLassetType === 'credits' && (
                  <Grid2 xs={12}>
                    <Button
                      onClick={handleOpenAssetCreateCreditModal}
                      disabled={isLoading}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('record_new_asset_button_' + URLassetType)}
                    </Button>
                  </Grid2>
                )}

                {/* Button for 'vehicles' asset type */}
                {URLassetType === 'vehicles' && (
                  <Grid2 xs={12}>
                    <Button
                      onClick={handleOpenAssetCreateVehicleModal}
                      disabled={isLoading}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('record_new_asset_button_' + URLassetType)}
                    </Button>
                  </Grid2>
                )}

                {/* Button for 'realestates' asset type */}
                {URLassetType === 'realestates' && (
                  <Grid2 xs={12}>
                    <Button
                      onClick={handleOpenAssetCreateRealEstateModal}
                      disabled={isLoading}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('record_new_asset_button_' + URLassetType)}
                    </Button>
                  </Grid2>
                )}
              </Grid2>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Grid2 container rowSpacing={5} columnSpacing={2} sx={{ width: '100%', m: 0, marginTop: '20px' }}>
        {/* Assets List and Summary Section */}

        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <CardContent>
              {!isLoading && (
                <>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <PageTitle>
                      {t('list_of_assets')}
                    </PageTitle>

                    <SettingsIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                  </div>

                  {/* First line: Valore Totale and Dimensione media investimento */}
                  <Typography variant="body1" >
                    {t('total_value')}:&nbsp;
                    <span style={{
                      ...cellTextStyle,
                      ...(PieDataTotal < 0 ? loss_color : gain_color),
                    }}>
                      {PieDataTotal.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                    </span>
                    <span style={{
                      paddingLeft: '10px',
                      paddingRight: '10px',
                      ...cellTextStyle
                    }}>|
                    </span>
                    {t('average_lot_size')}:&nbsp;
                    <span style={{
                      ...cellTextStyle,
                    }}>
                      {assets.length > 0 ? (
                        (PieDataTotal / assets.length).toLocaleString('en-AU', { style: 'currency', currency: base_currency })
                      ) : (
                        'N/A'
                      )}
                    </span>
                  </Typography>

                  {/* Second line: Profits */}
                  {totalProfit !== null && (
                    <Typography variant="body1" style={{ ...textStyle_small_light, marginTop: '5px' }}>
                      {t('total_profit')}:&nbsp;
                      <span style={{
                        ...cellTextStyle,
                        ...(totalProfit < 0 ? loss_color : gain_color),
                      }}>
                        {totalProfit.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                      </span>
                      <span style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        ...cellTextStyle
                      }}>|
                      </span>
                      {t('total_realized_profit')}:&nbsp;
                      <span style={{
                        ...cellTextStyle,
                        ...(totalRealizedPL < 0 ? loss_color : gain_color),
                      }}>
                        {totalRealizedPL.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                      </span>
                      <span style={{
                        paddingLeft: '10px',
                        paddingRight: '10px',
                        ...cellTextStyle
                      }}>|
                      </span>
                      {t('total_unrealized_profit')}:&nbsp;
                      <span style={{
                        ...cellTextStyle,
                        ...(totalUnrealizedPL < 0 ? loss_color : gain_color),
                      }}>
                        {totalUnrealizedPL.toLocaleString('en-AU', { style: 'currency', currency: base_currency })}
                      </span>
                    </Typography>
                  )}
                </>
              )}

              {isLoadingAssets ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                  <CircularProgress />
                </Box>
              ) : (
                <div style={{ overflowX: 'auto', width: '100%' }}>
                  {renderTableForCategory(URLassetType, 'value')}
                </div>
              )}
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      {/* Trend Chart Section */}

      <Grid2 container rowSpacing={5} columnSpacing={2} sx={{ width: '100%', m: 0, marginTop: '20px' }}>
        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)', padding: '10px' }}>
            <CardContent>
              <PageTitle>
                Trend Chart
              </PageTitle>
              <FormControl fullWidth style={{ marginBottom: '20px' }}>
                <InputLabel id="timeframe-select-label">Time Frame</InputLabel>
                <Select
                  labelId="timeframe-select-label"
                  id="timeframe-select"
                  value={timeFrame}
                  label="Time Frame"
                  onChange={(e) => {
                    setTimeFrame(e.target.value);
                    updateChartData(e.target.value);
                  }}
                >
                  <MenuItem value="1M">1 Month</MenuItem>
                  <MenuItem value="1Q">1 Quarter</MenuItem>
                  <MenuItem value="1Y">1 Year</MenuItem>
                  <MenuItem value="ALL">All</MenuItem>
                </Select>
              </FormControl>
              {isLoadingTrendChart ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                  <CircularProgress />
                </Box>
              ) : (
                <Line data={chartData} options={ChartOptions} />
              )}
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>

      <Grid2 container rowSpacing={5} columnSpacing={2} sx={{ width: '100%', m: 0, marginTop: '20px' }}>

        {/* Pie Charts Carousel Section */}

        <Grid2 size={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12 }}>
          <Card style={{ boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)' }}>
            <CardContent>
              {/* Pie Charts Carousel Section */}
              <Carousel>
                {carouselItems.map((item, index) => (
                  <Box key={`carousel-item-${index}`}>
                    <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                      <PageTitle>
                        {t(item.title + '_pie_title')}
                      </PageTitle>
                      <PieChartIcon onClick={handleOpenSettingsModal} style={{ cursor: 'pointer' }} />
                    </Box>
                    {isLoading ? (
                      <Box display="flex" justifyContent="center" alignItems="center" height="300px">
                        <CircularProgress />
                      </Box>
                    ) : (
                      item.renderFunc()
                    )}
                  </Box>
                ))}
              </Carousel>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </ThemeProvider >

  );
}

export default PageAssets;
